import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

Sentry.init({
  // @ts-ignore We set SENTRY_APP_DSN in root.tsx
  dsn: window.ENV.SENTRY_APP_DSN,
  // @ts-ignore We set ENV_NAME in root.tsx
  environment: window.ENV.ENV_NAME ?? "development",
  // @ts-ignore We set APP_VERSION in root.tsx
  release: window.ENV.APP_VERSION,

  tracesSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});